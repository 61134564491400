import React from 'react';
import PropTypes from 'prop-types';
import { RosteringContainer } from 'cccisd-laravel-appdefs';
import Tabs from 'cccisd-tabs';

export default class Manage extends React.Component {
    static propTypes = {
        verticalNav: PropTypes.array,
        verticalNavClass: PropTypes.string,
    };

    static defaultProps = {
        verticalNavClass: null,
    };

    constructor() {
        super();

        this.state = {};
    }

    render() {
        const { verticalNav } = this.props;
        let tabList = [];

        verticalNav.forEach(v => {
            tabList.push({
                name: v.handle,
                title: v.label,
                content: (
                    <RosteringContainer
                        content={v.contentConfig}
                        actionListeners={[
                            {
                                id: 'delete',
                                actionName: 'buttonDelete',
                            },
                            {
                                id: 'archive',
                                actionName: 'buttonArchive',
                            },
                            {
                                id: 'edit',
                                actionName: 'buttonEdit',
                            },
                            {
                                id: 'unarchive',
                                actionName: 'buttonUnarchive',
                            },
                        ]}
                    />
                ),
            });
        });

        return (
            <div key={this.state.key}>
                <h1>Manage</h1>
                <Tabs tabList={tabList} orientation="vertical" saveInHash />
            </div>
        );
    }
}
